import { useWindowSize } from "../Utils";

export default function ImageBreak() {
  const width = useWindowSize()[0];
  return (
    <div className="ImageBreak">
      <img
        className="image"
        alt="background"
        src={require("../images/background2.webp")}
      />
      <div className="box">
        <div className="separador">
          <div className="retangulo" />
          <div className="circulo" />
          <div className="retangulo" />
          <div className="retangulo" />
          {width >= 768 && (
            <>
              <div className="circulo" />
              <div className="retangulo" />
              <div className="retangulo" />
              {width >= 1150 && (
                <>
                  <div className="circulo" />
                  <div className="circulo" />
                  <div className="retangulo" />
                  <div className="retangulo" />
                  <div className="retangulo" />
                </>
              )}
            </>
          )}
        </div>
        <div className="separador">
          <div className="circulo" />
          <div className="circulo" />
          <div className="retangulo" />
          <div className="retangulo" />
          {width >= 768 && (
            <>
              <div className="retangulo" />
              <div className="retangulo" />
              <div className="retangulo" />
              {width >= 1150 && (
                <>
                  <div className="retangulo" />
                  <div className="circulo" />
                  <div className="retangulo" />
                  <div className="circulo" />
                  <div className="retangulo" />
                </>
              )}
            </>
          )}
        </div>
        <div className="separador">
          <div className="retangulo" />
          <div className="retangulo" />
          <div className="retangulo" />
          <div className="circulo" />
          {width >= 768 && (
            <>
              <div className="circulo" />
              <div className="retangulo" />
              <div className="circulo" />
              {width >= 1150 && (
                <>
                  <div className="retangulo" />
                  <div className="retangulo" />
                  <div className="circulo" />
                  <div className="retangulo" />
                  <div className="retangulo" />
                </>
              )}
            </>
          )}
        </div>
        <div className="separador">
          <div className="retangulo" />
          <div className="retangulo" />
          <div className="circulo" />
          <div className="retangulo" />
          {width >= 768 && (
            <>
              <div className="circulo" />
              <div className="retangulo" />
              <div className="retangulo" />
              {width >= 1150 && (
                <>
                  <div className="circulo" />
                  <div className="retangulo" />
                  <div className="retangulo" />
                  <div className="retangulo" />
                  <div className="circulo" />
                </>
              )}
            </>
          )}
        </div>
        <div className="separador">
          <div className="retangulo" />
          <div className="circulo" />
          <div className="retangulo" />
          <div className="retangulo" />
          {width >= 768 && (
            <>
              <div className="retangulo" />
              <div className="circulo" />
              <div className="retangulo" />
              {width >= 1150 && (
                <>
                  <div className="retangulo" />
                  <div className="retangulo" />
                  <div className="circulo" />
                  <div className="retangulo" />
                  <div className="circulo" />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
