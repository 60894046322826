import { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import SideBar from "./SideBar";

const pessoas = [
  {
    nome: "Diogo Capelo",
    subtitulo: "Instituto Superior Técnico / TreeTree2",
    localidade: "Lisboa, Portugal",
    imagem:
      "/diogo-capelo.webp",
    descricao:
      "Coordenador de projetos de aprendizagem científica e investigador em Astrofísica, acompanha as atividades da TreeTree2 desde 2017. Organizou e participou em vários projetos e eventos de aprendizagem ligados à Ciência, com o foco no desenvolvimento integral dos alunos em ambiente escolar.",
  },

  {
    nome: "Pedro Marcelino",
    subtitulo: "TreeTree2",
    localidade: "Lisboa, Portugal",
    imagem:
      "/pedro-marcelino.webp",
    descricao:
      "Co-fundador da TreeTree2, uma associação sem fins lucrativos que tem como missão despertar e desenvolver talentos nas áreas da Ciência, Engenharia, Tecnologia e Matemática. Doutorado pelo Instituto Superior Técnico, tendo realizado trabalho de investigação no Laboratório Nacional de Engenharia Civil (LNEC).",
  },
];

const collab = [
  {
    title: "Página",
    categories: [
      { title: "Design", people: ["Paulo Graça"] },
      {
        title: "Programação", people: ["Francisco Salgueiro"],
      },
    ],
  },
  {
    title: "Conteúdos",
    categories: [
      {
        title: "Revisão e Adaptação",
        people: [
          "Ana Lopes",
          "Catarina Miranda",
          "Joana Bordalo",
        ],
      },
      {
        title: "Vídeo de animação",
        people: ["Produtora Viralata"],
      },
    ],
  },
  {
    title: "Escola",
    categories: [
      {
        title: "Professores",
        people: [
          "Nuno Santos",
          "Fátima Dias",
          "Tito Mendonça",
          "Isabel Rovisco",
          "Maria Florindo",
        ],
      },
    ],
  },
];

export default function Equipa() {
  let [p, setPessoa] = useState(0);

  return (
    <>
      <div className="Equipa" id="equipa">
        <SideBar title={"EQUIPA"} />
        <div className="avatar">
          <div className="circle">
            <img src={pessoas[p].imagem} alt={pessoas[p].nome} />
            <span className="blue" />
          </div>

          <div className="controls">
            <button
              onClick={() =>
                setPessoa(p === 0 ? pessoas.length - 1 : p - 1)
              }
              aria-label="pessoa anterior"
            >
              <FaArrowLeft />
            </button>
            <button
              onClick={() =>
                setPessoa(p === pessoas.length - 1 ? 0 : p + 1)
              }
              aria-label="próxima pessoa"
            >
              <FaArrowRight />
            </button>
          </div>
        </div>
        <div className="text">
          <h1>
            <b>{pessoas[p].nome}</b>
            <br />
            {pessoas[p].subtitulo}
            <br />
            {pessoas[p].localidade}
          </h1>
          <p>{pessoas[p].descricao}</p>
        </div>
      </div>
      <div className="Colaboradores">
        {collab.map((c, i) => (
          <div className="text" key={i}>
            <h1>{c.title}</h1>
            {c.categories.map((cat, i) => (
              <p key={i}>
                <b>{cat.title}</b>
                <br />
                {cat.people.map((p, i) => (
                  <span key={i}>
                    {p}
                    <br />
                  </span>
                ))}
              </p>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}
