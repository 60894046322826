import Accordion from "./Accordion";
import SideBar from "./SideBar";

const modules = [
  {
    title: "01. Eu e a Programação",
    content: "Este módulo debruça-se sobre o papel da tecnologia nas nossas vidas e na sociedade, e na distinção de alguns conceitos fundamentais como programa e código, ou linguagem natural e linguagem de programação. No final deste módulo, os alunos completam o seu primeiro programa simples.",
  },
  {
    title: "02. Expressões e Valores",
    content: "O código dos programas de computador depende de duas peças fundamentalmente diferentes: as expressões, que são instruções que damos ao computador para ele realizar certas tarefas, e os valores, dados simples que ajudam a dizer às expressões exatamente o que fazer e em que circunstâncias. Também é introduzido o conceito fundamental de variável e explorados os vários tipos que estas podem assumir.",
  },
  {
    title: "03. Input",
    content: "Este módulo foca-se na interação com o utilizador e, sobretudo, em como obter e utilizar respostas a perguntas que fazemos ao utilizador de um programa, e mudar o funcionamento do nosso programa de acordo com isso.",
  },
  {
    title: "04. Expressões de Comparação",
    content: "As comparações são um tipo especial de operação que permitem determinar se duas ou mais coisas (que podemos ou não saber o que são!) são iguais ou diferentes. São aqui introduzidas como forma de comparar os dados recebidos do utilizador com as nossas expetativas, permitindo-nos alterar dinamicamente o funcionameto dos nossos programas.",
  },
  {
    title: "05. Expressões Condicionais",
    content: "A introdução das expressões if e else permite criar ramos de execução do programa, conforme uma dada circunstância se verifique ou não. Por essa razão, são uma ferramenta fundamental em programação. Através de exemplos do dia-a-dia, é feita uma abordagem às condições, a sua utilidade, e a sua implementação num programa.",
  },
  {
    title: "06. Listas",
    content: "Tal como na nossa vida podemos usar listas para organizar e tornar mais acessível alguma informação (por exemplo, uma lista de compras ou de tarefas a fazer), também em programação as listas podem ajudar-nos a manter o nosso código simples e organizado, e facilitar vários processos de verificação e utilização.",
  },
];

export default function Curso() {
  return (
    <>
      <SideBar title={"CURSO"} />
      <div className="Curso" id="curso">
        <h1>Curso de introdução à programação</h1>
        <p>
          O curso tem seis módulos pré-preparados, com cerca de 120 
          a 240 minutos de conteúdo, que podem ser disponibilizados 
          aos alunos do 2º e 3º ciclos do Ensino Básico 
          em formato de passo próprio (self-paced) ou 
          incorporados em componentes curriculares, de acordo com 
          as necessidades do Professor e das turmas. Todos os módulos
          dispõem também de um guião para o Professor com as dúvidas 
          mais comuns e os erros mais frequente em cada exercício, com 
          sugestões de abordagem.
        </p>
        <div className="Accordion">
          <div className="divider" />
          {modules.map((module, index) => (
            <div key={index}>
              <Accordion
                title={module.title}
                content={module.content}
              />
              <div className="divider" />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
