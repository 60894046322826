import { ReactComponent as LogoTreeTree2 } from "../images/logo-tree.svg";

export default function Logos() {
  return (
    <div className="Logos">
      <a href="https://treetree2.org"><LogoTreeTree2 style={{width: "177px"}} /></a>
      <a href="https://ist.pt"><img src={require("../images/logo-ist.png")} alt="IST" /></a>
      <a href="https://bpi.pt"><img src={require("../images/logo-bpi.png")} alt="BPI" /></a>
      <a href="https://fundacaolacaixa.pt/"><img src={require("../images/logo-lacaixa.png")} alt="Fundação la Caixa" /></a>
    </div>
  )
}
