import SideBar from "./SideBar";
import Slides from "./Slides";
import { useWindowSize } from "../Utils";

export default function Missao() {
  const width = useWindowSize()[0];

  return (
    <>
      <SideBar title={"MISSÃO"} />
      <div className="Missao" id="missao">
        <h1>
          A inclusão digital é uma das ferramentas mais fortes de inclusão 
          social, e a programação uma das habilitações técnicas mais 
          valorizadas no séc. XXI - agora ao alcance de todos.

        </h1>

        <p>
          Porque razão a programação deve estar apenas ao alcance de alguns? 
          O curso de introdução à programação em Python procura destruir 
          as desigualdades no acesso à informação e educação de qualidade, 
          colocando ao dispôr de todos vários módulos abrangendo os básicos 
          da programação, direcionados a quem nunca antes programou.
        </p>

        <div className="video">
          <div className="player">
            <lite-youtube
              videoid="SiR2sXv_M0k"
              videotitle="Vídeo de apresentação"
            ></lite-youtube>
          </div>
          <h1>
            Programação. Para todos.
          </h1>
        </div>
      </div>
      <div className="Missao-2">
        <div className="text">
          <h1>Como posso começar a disponbilizar a Programação Para Todos?</h1>
          <p>
            Se tem interesse no projeto e pretende implementar atividades 
            baseadas no curso de programação do Programação Para Todos, 
            contacte o endereço de email <a href="mailto:aprender@treetree2.org" className="email">aprender@treetree2.org</a>.
          </p>
        </div>
        <div className="box">
          <div className="separador">
            <span className="circulo" />
            <span className="retangulo" />
            <span className="retangulo" />
          </div>
          <div className="separador">
            <span className="retangulo" />
            <span className="circulo" />
          </div>
        </div>
      </div>
      <Slides />
      <div className="separador">
        <span className="retangulo" />
        <span className="circulo" />
        <span className="circulo" />
        {width >= 768 && (
          <>
          <span className="retangulo" />
            <span className="retangulo" />
            <span className="retangulo" />

            <span className="circulo" />
            {width >= 1150 && (
              <>
                <span className="retangulo" />
                <span className="retangulo" />

                <span className="circulo" />
                <span className="retangulo" />
                <span className="retangulo" />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
