import "./App.scss";
import Header from "./components/Header";
import Missao from "./components/Missao";
import Curso from "./components/Curso";
import Equipa from "./components/Equipa";
import Footer from "./components/Footer";
import Logos from "./components/Logos";
import ImageBreak from "./components/ImageBreak";

function App() {
  return (
    <>
      <Header />
      <Missao />
      <Curso />

      <ImageBreak />

      <Equipa />
      <Logos />
      <Footer />
    </>
  );
}

export default App;
