import { Link } from "react-scroll";
import { ReactComponent as LogoPPT } from "../images/logo-ppt-yellow.svg";
import GeometryPattern from "./GeometryPattern";
import SideBar from "./SideBar";

const links = [
  {
    name: "missão",
    target: "missao",
  },
  {
    name: "curso",
    target: "curso",
  },
  {
    name: "equipa",
    target: "equipa",
  },
];

export default function Header() {
  return (
    <div className="Header">
      <GeometryPattern />
      <a href=".">
        <LogoPPT className="logo" />
      </a>
      <div className="navbar">
        {links.map((link, index) => {
          return (
            <Link
              key={index}
              to={link.target}
              spy
              smooth
              offset={-70}
              duration={500}
              className="link"
              href={"#" + link.target}
            >
              {link.name}
            </Link>
          );
        })}
      </div>
      <div className="title">
        <b>
          para ti.
          <br />
          para todos.
        </b>
        <p>
          inclusão através da tecnologia. <br /> 
          tecnologia ao serviço da educação.
        </p>
        <Link
          to={links[0].target}
          spy
          smooth
          offset={-70}
          duration={500}
          className="btn"
          href={"#" + links[0].target}
        >
          descobre mais
        </Link>
      </div>
      <SideBar title={"Scroll"} className="side" />
    </div>
  );
}
